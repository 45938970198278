@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.finalPersonalData {
  font-size: 1.2rem;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #242121;
}

#invoiceAddressIsOther {
  margin-left: 10px;
  color: grey;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  font-size: 1.2rem;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #ff4545;
  border-radius: 50%;
}

/* The container */
.checkBoxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.2rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* Hide the browser's default radio button */
.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the radio button is checked, add a blue background */
.checkBoxContainer input:checked ~ .checkmark {
  background-color: #ff4545;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkBoxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkBoxContainer .checkmark:after {
  top: 4px;
  left: 4.1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.finalItems {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 1.2rem;
  line-height: 0px;
  height: 0px;
}

#finalWholePizzaName {
  color: #242121;
}

#finalWholePizzaPrice {
  color: grey;
}

#finalWholePizzaSumPrice {
  color: #242121;
}

#finalExtraName {
  font-size: 1rem;
  font-style: italic;
  color: grey;
}

#finalSlicePizzaName {
  color: #242121;
}

#finalSlicePizzaPrice {
  color: grey;
}

.finalDelivery {
  font-style: italic;
  color: grey;
}

#finalSumGrossPriceText {
  font-weight: bold;
  color: #242121;
}

#finalSumGrossPrice {
  font-weight: bold;
  color: #ff4545;
}

#borderGuest {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 70px;
  width: 100%;
  font-family: "Fira Sans Condensed", sans-serif;
}

#borderGuest #videoBcg {
  position: absolute;
  width: inherit;
  height: inherit;
  object-fit: fill;
}

#borderGuest .video-overlay {
  position: absolute;
  width: 100%;
  height: 80vh;
  background-color: #242121;
  opacity: 0.6;
}

#borderGuest #formOnVideo {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: whitesmoke;
  width: 100%;
  height: 80vh;
  font-size: 18px;
}

#borderGuest #formOnVideo #opinionFormOpen {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
  width: 30%;
  height: 80%;
  border: 1px solid #ff4545;
  border-radius: 6px;
  background-color: #242121;
  opacity: 0.8;
  box-shadow: 2px 2px 10px #242121;
}

#borderGuest #formOnVideo input,
#borderGuest #formOnVideo textarea {
  font-family: "Fira Sans Condensed", sans-serif;
  outline: none;
  padding: 3px;
  font-size: 17px;
  color: whitesmoke;
  margin: 10px;
  border: 1px solid whitesmoke;
  font-style: italic;
  background-color: #242121;
}

#borderGuest #formOnVideo input:focus,
#borderGuest #formOnVideo textarea:focus {
  border-color: #ff4545;
}

#borderGuest #formOnVideo #guestSubmit {
  margin-top: 3rem;
}

#borderGuest #filteringBar {
  font-family: "Fira Sans Condensed", sans-serif;
  border: 1px solid grey;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #242121;
  height: 30px;
  width: 100%;
  font-weight: 600;
}

#borderGuest #filteringBar #guestBookFilter {
  font-size: 15px;
  padding: 5px 10px;
  outline: none;
}

#borderGuest #filteringBar #guestBookFilter:focus {
  background-color: #ff4545;
  color: whitesmoke;
}

#guestReviews {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 5px;
  width: 100%;
}

#guestReviews .reviews {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 400px;
  height: 400px;
}

#guestReviews .reviews .card {
  display: -webkit-flex;
  display: flex;
  font-size: 18px;
  color: #242121;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 80%;
  width: 80%;
  padding: 20px;
  text-align: center;
  box-shadow: 2px 2px 10px;
  border-radius: 250px 250px 250px 250px;
  background-image: url("/img/empty-pizza.jpg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

#guestReviews .reviews .card #reviewComment {
  margin-top: 1.3rem;
  padding: 30px;
}

#guestReviews .reviews .card #foodRate {
  color: #ff4545;
  font-size: 40px;
}

.star {
  visibility: hidden;
  font-size: 20px;
  cursor: pointer;
}

.star:before {
  content: "\2606";
  color: whitesmoke;
  font-size: 25px;
  position: absolute;
  visibility: visible;
}

.star:checked:before {
  content: "\2605";
  color: #ff4545;
  font-size: 25px;
  position: absolute;
}

#underComponentContainer {
  width: 100vw;
  height: 100vh;
}

#navBarMask {
  height: 10vh;
}

#underConstructionContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: calc(100% - 10vh);
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #242121;
}

.profile-container {
  width: 100%;
  height: 100vh;
  background-color: #ff4545;
}

.profile-img-adam {
  width: 100%;
  height: 100vh;
  background-image: url("/img/Adam2021-50.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profile-img-zoli {
  width: 100%;
  height: 100vh;
  background-image: url("/img/Zoli2021-50.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.box-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2rem;
  padding: 1rem;
  background-color: #242121;
  color: white;
  font-family: "Fira Sans Condensed", sans-serif;
  line-height: 2rem;
  width: 100%;
  height: 70%;
}

.box-2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
  color: #242121;
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 1.5rem;
  width: 100%;
  height: 30%;
  border: 1px solid #242121;
}

.contact-list {
  list-style: none;
}

.contact-list li {
  margin-bottom: 1rem;
  text-align: center;
}

.link-list {
  display: -webkit-flex;
  display: flex;
  list-style: none;
  margin-top: 0;
}

.welcome-message {
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}

.technologiesZ {
  font-weight: 600;
}

#welcome-message {
  font-size: 1.5rem;
  color: #242121;
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 600;
  text-decoration: underline;
}

#introducing-txt {
  text-align: center;
}

#technologies {
  list-style-type: none;
  text-align: center;
  margin-top: 0;
  font-weight: 600;
}

#introducing-txt2 {
  text-align: center;
}

.my-contacts {
  margin-bottom: 0;
  font-weight: 600;
  text-decoration: underline;
}

#phone-icon {
  font-size: 1.5rem;
  color: #242121;
  margin-right: 0.5rem;
}

#whatsapp-icon {
  font-size: 1.6rem;
  color: #075e54;
}

#email-icon {
  font-size: 1.6rem;
  color: #242121;
}

#github-icon {
  font-size: 2rem;
  color: #242121;
  margin-left: 1rem;
}

#componentContainer {
  width: 100vw;
  height: 100vh;
}

.food-types {
  color: #1e1c1c;
  font-size: 1.1rem;
  font-weight: 700;
  font-family: "Fira Sans Condensed", sans-serif;
  padding: 0.7rem;
}

.order-container {
  background-color: whitesmoke;
  width: 100%;
  margin-right: 23%;
}

.order-headerContainer {
  margin: 0 10px 10px 10px;
  background-image: url("/img/oven3.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sideBar {
  width: 23%;
  height: 50vh;
  background-color: white;
}

.side-container {
  max-height: 200%;
  width: 100%;
  overflow-y: scroll;
}

.container {
  width: 100%;
  font-size: 1rem;
}

.sum-price {
  font-size: 1rem;
  color: grey;
}

.order-extra {
  font-size: 1rem;
  color: grey;
  margin: 5px 0 1px 0;
}

.delivery-price {
  color: grey;
}

#parent-container {
  width: 100%;
  position: absolute;
  top: 11%;
}

#empty-cart1 {
  font-size: 1.5rem;
  color: black;
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 700;
}

#empty-cart2 {
  font-size: 1rem;
  color: gray;
}

#discountedPizza {
  color: whitesmoke;
  background-color: #242121;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: white;
}

body h4 {
  width: 100%;
  color: white;
  font-size: 4rem;
  text-align: center;
  font-family: "Dancing Script";
  background-color: #1e1c1c;
}

body h5 {
  font-family: "Fira Sans Condensed", sans-serif;
  margin: 5px;
  width: 100%;
  color: #1e1c1c;
  font-size: 3rem;
  text-align: center;
  font-family: "Fira Sans Condensed", sans-serif;
  padding: 10px 10px 10px 10px;
  background-color: whitesmoke;
}

body .space {
  min-width: 20px;
}

body .buttonContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

body .imgSlider-button :hover {
  color: #ff4545;
  transition: 1s ease-in-out;
  cursor: pointer;
}

body .buttonStyle {
  cursor: pointer;
  width: 180px;
  height: 40px;
  color: white;
  border-radius: 5px;
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 18px;
  text-align: center;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(45deg, #e9b4b4, #db3a3a);
  margin: 5px;
  border: 0px;
  font-weight: 700;
  transition: 3s;
  z-index: 1;
}

body .buttonStyle:hover {
  color: black;
  box-shadow: 0px 0px 15px 1px inset whitesmoke;
}

body .burger-menu {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  top: 0;
  height: 10vh;
  padding: 0 1rem 0 1rem;
  color: #1e1c1c;
  background-color: white;
  border-bottom: 4px solid #21d121;
  margin-bottom: 1rem;
}

body .burger-menu #burger {
  cursor: pointer;
}

body .burger-menu .top {
  background-color: #21d121;
}

body .burger-menu .middle {
  background-color: #e6dfdf;
}

body .burger-menu .bottom {
  background-color: #ff4545;
}

body .burger-layer {
  width: 35px;
  height: 5px;
  background-color: whitesmoke;
  margin: 6px 0;
}

body .sideBar {
  position: fixed;
}

body .logo-header {
  width: 400px;
  height: 400px;
  -webkit-clip-path: inset(0px 0px 0px round 400px 400px 400px 400px);
          clip-path: inset(0px 0px 0px round 400px 400px 400px 400px);
  left: calc(50% - 400px / 2);
  top: 20%;
  background-color: #1e1c1c;
  opacity: 0.6;
  color: whitesmoke;
  font-family: "Dancing Script", sans-serif;
  -webkit-animation: logo-spin 3s linear;
          animation: logo-spin 3s linear;
}

@-webkit-keyframes logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    width: 0px;
    height: 0px;
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    width: 400px;
    height: 400px;
  }
}

@keyframes logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    width: 0px;
    height: 0px;
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    width: 400px;
    height: 400px;
  }
}

body #pizzaWholeFigure {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #1e1c1c;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 0px;
}

body .pizzaSliceFigure {
  width: 40px;
  height: 40px;
}

body .modal-bg {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 123vw;
  height: 100%;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
}

body .modal-content {
  position: absolute;
  left: 45%;
  top: 50%;
  width: 38vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 5%;
}

body .close-modal {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  top: 15px;
  right: 24%;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: white;
  border: 1px solid black;
  color: #ff4545;
  font-weight: 900;
  z-index: 1;
  cursor: pointer;
}

body .card {
  cursor: pointer;
}

body .schedule {
  width: 100vw;
  height: 70vh;
  background-color: white;
  font-family: "Fira Sans Condensed", sans-serif;
}

body .hours-container {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #ff4545;
  color: white;
}

body .wood-background {
  background-image: url("/img/wood.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body .offer-section {
  background-image: url("/img/oven2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body .event-container {
  width: 800px;
  height: 300px;
  border-radius: 50%;
  border: 4px solid #ff4545;
  background-color: #1e1c1c;
  color: white;
}

body #benvenuti {
  width: 100%;
  height: 60vh;
}

body #benvenuti #benvenutiRightSide {
  width: 100%;
  height: 60vh;
  background-color: #1e1c1c;
}

body #benvenuti #benvenutiTitle {
  color: white;
  font-size: 4rem;
  font-family: "Dancing Script";
}

body #benvenuti h5 {
  font-size: 1.8rem;
}

body #benvenuti #benvenutiText {
  width: 100%;
  height: 60vh;
  padding: 1.5rem;
  color: #1e1c1c;
  line-height: 1.8rem;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: justify;
  font-family: "Fira Sans Condensed", sans-serif;
}

body #foodMenu {
  width: 100%;
  height: 100%;
}

body #foodMenu #foodMenuTitle {
  width: 100%;
}

body #foodMenu #foodMenuTitle h4 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  padding: 1rem;
  font-size: 4rem;
  text-align: center;
  font-family: "Dancing Script";
  background-color: #1e1c1c;
}

body #foodMenu #foodMenuTitle #foodMenuBox {
  width: 90%;
  margin-right: 3rem;
}

body #foodMenu #foodMenuTitle #foodMenuBox #foodMenuList {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 1.5rem;
  list-style: none;
  font-weight: 700;
}

body #foodMenu .vespa-img {
  background-image: url("/img/vespa.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body #foodMenu .menu {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.7;
}

body #foodMenu .menu #pizzaMenu,
body #foodMenu .menu #dessertMenu,
body #foodMenu .menu #drinkMenu {
  font-family: "Fira Sans Condensed", sans-serif;
  color: #1e1c1c;
  letter-spacing: 0.1rem;
  cursor: pointer;
}

body #foodMenu .menu #dessertMenu {
  letter-spacing: 0.1rem;
}

body #foodMenu .menu .menuFood {
  width: 100%;
  height: 100vh;
  font-family: "Fira Sans Condensed", sans-serif;
  line-height: normal;
  border-color: red;
  padding: 20px 40px 20px 20px;
}

body #foodMenu .menu #premiumRight-container {
  margin-top: 8%;
}

body #foodMenu .menu .menuFoodTitle {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 800;
  color: white;
  letter-spacing: 0.1rem;
  font-style: italic;
  text-decoration: underline;
}

body #foodMenu .menu .menuFoodName,
body #foodMenu .menu .menuFoodPrice {
  font-size: 1.2rem;
  font-weight: 800;
  color: #ff4545;
  letter-spacing: 0.1rem;
}

body #foodMenu .menu .menuFoodDetails {
  font-size: 1rem;
  font-weight: 400;
  color: #d1cfcf;
  letter-spacing: 0.1rem;
  font-style: italic;
}

body .nav {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  height: 10vh;
  top: 0;
  color: white;
  background-color: #242121;
  border-bottom: 4px solid #21d121;
}

body .flag {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 11%;
  top: 0;
  padding: 0 1rem 0 1rem;
  background-color: whitesmoke;
  border-bottom: 4px solid #ff4545;
  z-index: 1;
}

body .logo {
  margin-left: 1%;
}

body .logo img {
  height: 80px;
}

body .logo #logo-txt {
  margin-left: 1rem;
  font-family: "Dancing Script";
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
}

body #homeDelivery,
body #openToday {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  color: #242121;
}

body #openToday {
  text-decoration: underline;
}

body .nav-buttons {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

body .nav-links {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 700;
  width: 100%;
}

body .nav-links .nav-button {
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
}

body .nav-links .nav-button:hover {
  color: #ff4545;
  transition: 1s ease-in-out;
}

body .nav-links .is-active {
  color: #ff4545;
}

body .cart {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 1.2rem;
  margin-left: 0.5rem;
  font-weight: 600;
}

body #guestBook {
  width: 100%;
  height: 60vh;
  font-family: "Fira Sans Condensed", sans-serif;
}

body #guestBook #guestBookRightSide {
  width: 100%;
  height: 60v;
  padding: 1.5rem;
  color: #1e1c1c;
  line-height: 2.2rem;
  font-size: 1.6rem;
  font-size: 600;
  text-align: center;
}

body #guestBook #guestBookRightSide #review-container {
  width: 100%;
  padding: 20px;
}

body #offers {
  width: 100%;
  height: 80vh;
  font-family: "Fira Sans Condensed", sans-serif;
}

body #offers .offerBox {
  padding: 20px;
}

body #offers .offerBox .offerName aperitivo {
  margin-top: 0;
}

body #offers .offerTitle {
  font-weight: 900;
  color: #ff4545;
  letter-spacing: 0.3rem;
  font-size: 1.5rem;
  text-decoration: underline;
  padding: 10px;
}

body #offers .offerName {
  font-size: 1.4rem;
  font-weight: 900;
  color: #1e1c1c;
  letter-spacing: 0.1rem;
}

body #offers .offerDetails {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1e1c1c;
  letter-spacing: 0.1rem;
  font-style: italic;
  text-align: center;
}

body #offers .offerPrice {
  font-size: 1.6rem;
  font-weight: 900;
  color: #f4545c;
}

body #offers #special-offer {
  font-weight: 900;
  color: white;
  letter-spacing: 0.3rem;
  font-size: 1.9rem;
  text-decoration: underline;
}

body .pizza-img1 {
  background-image: url("/img/oven1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body .offer-img {
  background-image: url("/img/pizzeriaInside.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body #daily-offer {
  font-weight: 900;
  color: #ff4545;
  letter-spacing: 0.3rem;
  font-size: 1.5rem;
  text-decoration: underline;
}

body #order-onlineBurg {
  font-family: "Fira Sans Condensed", sans-serif;
  padding: 13px 20px 10px 20px;
  background-color: white;
  margin-right: 1rem;
  font-weight: 900;
  border: 1px solid #1e1c1c;
}

body #checked {
  color: #2691d9;
}

body #review-btn {
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;
}

body #pPrice {
  font-size: 1.1rem;
  color: grey;
}

body #counterWhole {
  color: whitesmoke;
}

body #quantity {
  font-size: 1rem;
}

body #extras {
  font-size: 1rem;
  margin-left: 1rem;
}

body #extrPrice {
  font-size: 1rem;
  color: grey;
}

body #btn-add {
  outline: none;
  border: none;
  padding: 8px 16px;
  background-color: #ff4545;
  border-left: 1px solid #ff4545;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: whitesmoke;
  margin: 0 0 0.2rem 0.2rem;
}

body #btn-remove {
  outline: none;
  border: none;
  padding: 8px 16px;
  background-color: #ff4545;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: whitesmoke;
  margin: 0 0 0.2rem 0.2rem;
}

body #addToCart {
  outline: none;
  border: none;
  padding: 12px 24px;
  background-color: #ff4545;
  font-weight: bold;
  color: whitesmoke;
}

body .goToOrder {
  border: none;
  outline: none;
  background-color: #ff4545;
  color: whitesmoke;
  margin-top: 5rem;
  padding: 18px 40px;
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
}

body .slides {
  margin-top: 6rem;
  width: 100%;
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body .orderList-counter {
  border: none;
  outline: none;
  background-color: white;
  color: #2691d9;
  cursor: pointer;
  font-size: 1rem;
}

body #orderListCount {
  border: none;
  outline: none;
  background-color: white;
  font-size: 0.9rem;
  font-weight: bold;
}

body .offerPic-container {
  background-image: url("/img/oven2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body .menuPic {
  background-image: url("/img/pizza1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body .detail-header {
  width: 100%;
  height: 25vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body #pizzaBar {
  background-color: white;
  width: 100%;
  margin: 1.5%;
  padding-left: 10px;
  padding-right: 20px;
}

body #pizzaName {
  color: #242121;
  font-size: 1.3rem;
  font-weight: 500;
  margin-right: 1rem;
}

body #pizzaIngredients {
  color: #242121;
  font-size: 1rem;
}

body #pizzaPriceSum {
  font-size: 1.1rem;
  color: #242121;
  font-weight: 700;
}

body .extraTopping {
  font-size: 1rem;
  cursor: pointer;
  color: #1e1c1c;
}

body .chooseTopping {
  font-size: 1rem;
  color: #1e1c1c;
  cursor: pointer;
}

body .footer {
  width: 100%;
  padding: 1rem;
  bottom: 0;
  font-family: "Fira Sans Condensed", sans-serif;
  background-color: white;
  border-top: 1px solid #d3d2d2;
}

body .footer .footerTitle {
  font-weight: 700;
  font-size: 1.3rem;
  color: #ff4545;
  text-decoration: underline;
  letter-spacing: 0.1rem;
}

body .footer .footerDetails {
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 00;
  font-size: 1rem;
  color: #1e1c1c;
  margin-top: 0;
  letter-spacing: 0.1rem;
}

body .form-container {
  position: relative;
}

body .finalLeft {
  position: relative;
  top: 200px;
  font-family: "Fira Sans Condensed", sans-serif;
}

body .finalRight {
  position: relative;
  top: 200px;
}

body legend {
  color: #5f5e5e;
}

body .pizzaSumExtra {
  font-size: 1.2rem;
  font-weight: 400;
  color: #1e1c1c;
  font: "Fira Sans Condensed", sans-serif;
}

body .cardValid-input {
  border: none;
  outline: none;
  background-color: white;
  padding: 8px 2rem 8px 2px;
}

body .cardValid-input.cvc {
  margin-left: 2rem;
}

body .cardNumb-input {
  border: none;
  outline: none;
  background-color: white;
  padding: 8px 25rem 8px 2px;
}

body .cardNumb-input:focus {
  border: none;
}

#booking-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: black;
}

#booking {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: black;
}
/*# sourceMappingURL=App.css.map */
